@use 'sass:color';

/* Base colors */

// Brand
$brandColor: #1a1a1a;
$brandColorAlternate: #f4f4f4;

// Content background
$contentBackgroundColor: #fff;
$contentBackgroundColorCard: #f4f4f4;
$contentBackgroundColorFocus: #eaeaea;
$contentBackgroundColorDark: #010101;
$contentBackgroundColorCardDark: #1a1a1a;
$contentBackgroundColorFocusDark: #272727;

// Alert tint
$alertColor: #ea0029;

// Light
$alertColorHover: color.adjust($alertColor, $lightness: -5%);
$alertColorActive: color.adjust($alertColor, $lightness: -10%);
$alertColorCard: #e10028;
$alertColorCardHover: color.adjust($alertColorCard, $lightness: -5%);
$alertColorCardActive: color.adjust($alertColorCard, $lightness: -10%);
$alertColorFocus: #d40025;
$alertColorFocusHover: color.adjust($alertColorFocus, $lightness: -5%);
$alertColorFocusActive: color.adjust($alertColorFocus, $lightness: -10%);
$alertColorMidtone: #cc0024;
$alertColorMidtoneHover: color.adjust($alertColorMidtone, $lightness: -5%);
$alertColorMidtoneActive: color.adjust($alertColorMidtone, $lightness: -10%);

// Dark
$alertColorHoverDark: color.adjust($alertColor, $lightness: 5%);
$alertColorActiveDark: color.adjust($alertColor, $lightness: 10%);
$alertColorCardDark: #ff153e;
$alertColorCardHoverDark: color.adjust($alertColorCard, $lightness: 5%);
$alertColorCardActiveDark: color.adjust($alertColorCard, $lightness: 10%);
$alertColorFocusDark: #ff4666;
$alertColorFocusHoverDark: color.adjust($alertColorFocus, $lightness: 5%);
$alertColorFocusActiveDark: color.adjust($alertColorFocus, $lightness: 10%);
$alertColorMidtoneDark: #ff93a5;
$alertColorMidtoneHoverDark: color.adjust($alertColorMidtoneDark, $lightness: 5%);
$alertColorMidtoneActiveDark: color.adjust($alertColorMidtoneDark, $lightness: 10%);

// Creation tint
$creationColor: #008656;

// Light
$creationColorHover: color.adjust($creationColor, $lightness: -5%);
$creationColorActive: color.adjust($creationColor, $lightness: -10%);
$creationColorCard: #008052;
$creationColorCardHover: color.adjust($creationColorCard, $lightness: -5%);
$creationColorCardActive: color.adjust($creationColorCard, $lightness: -10%);
$creationColorFocus: #00784d;
$creationColorFocusHover: color.adjust($creationColorFocus, $lightness: -5%);
$creationColorFocusActive: color.adjust($creationColorFocus, $lightness: -10%);
$creationColorMidtone: #00744a;
$creationColorMidtoneHover: color.adjust($creationColorMidtone, $lightness: -5%);
$creationColorMidtoneActive: color.adjust($creationColorMidtone, $lightness: -10%);

// Dark
$creationColorHoverDark: color.adjust($creationColor, $lightness: 5%);
$creationColorActiveDark: color.adjust($creationColor, $lightness: 10%);
$creationColorCardDark: #009660;
$creationColorCardHoverDark: color.adjust($creationColorCardDark, $lightness: 5%);
$creationColorCardActiveDark: color.adjust($creationColorCardDark, $lightness: 10%);
$creationColorFocusDark: #00a268;
$creationColorFocusHoverDark: color.adjust($creationColorFocusDark, $lightness: 5%);
$creationColorFocusActiveDark: color.adjust($creationColorFocusDark, $lightness: 10%);
$creationColorMidtoneDark: #00cd83;
$creationColorMidtoneHoverDark: color.adjust($creationColorMidtoneDark, $lightness: 5%);
$creationColorMidtoneActiveDark: color.adjust($creationColorMidtoneDark, $lightness: 10%);

// Navigation tint
$navigationColor: #0079d2;

// Light
$navigationColorHover: color.adjust($navigationColor, $lightness: -5%);
$navigationColorActive: color.adjust($navigationColor, $lightness: -10%);
$navigationColorCard: #0072c8;
$navigationColorCardHover: color.adjust($navigationColorCard, $lightness: -5%);
$navigationColorCardActive: color.adjust($navigationColorCard, $lightness: -10%);
$navigationColorFocus: #006cbc;
$navigationColorFocusHover: color.adjust($navigationColorFocus, $lightness: -5%);
$navigationColorFocusActive: color.adjust($navigationColorFocus, $lightness: -10%);
$navigationColorMidtone: #0067b2;
$navigationColorMidtoneHover: color.adjust($navigationColorMidtone, $lightness: -5%);
$navigationColorMidtoneActive: color.adjust($navigationColorMidtone, $lightness: -10%);

// Dark
$navigationColorHoverDark: color.adjust($navigationColor, $lightness: 5%);
$navigationColorActiveDark: color.adjust($navigationColor, $lightness: 10%);
$navigationColorCardDark: #0084e6;
$navigationColorCardHoverDark: color.adjust($navigationColorCardDark, $lightness: 5%);
$navigationColorCardActiveDark: color.adjust($navigationColorCardDark, $lightness: 10%);
$navigationColorFocusDark: #0090fa;
$navigationColorFocusHoverDark: color.adjust($navigationColorFocusDark, $lightness: 5%);
$navigationColorFocusActiveDark: color.adjust($navigationColorFocusDark, $lightness: 10%);
$navigationColorMidtoneDark: #5ebbff;
$navigationColorMidtoneHoverDark: color.adjust($navigationColorMidtoneDark, $lightness: 5%);
$navigationColorMidtoneActiveDark: color.adjust($navigationColorMidtoneDark, $lightness: 10%);

// Notification tint
$notificationColor: #857550;

// Light
$notificationColorHover: color.adjust($notificationColor, $lightness: -5%);
$notificationColorActive: color.adjust($notificationColor, $lightness: -10%);
$notificationColorCard: #7e6e4c;
$notificationColorCardHover: color.adjust($notificationColorCard, $lightness: -5%);
$notificationColorCardActive: color.adjust($notificationColorCard, $lightness: -10%);
$notificationColorFocus: #766847;
$notificationColorFocusHover: color.adjust($notificationColorFocus, $lightness: -5%);
$notificationColorFocusActive: color.adjust($notificationColorFocus, $lightness: -10%);
$notificationColorMidtone: #716344;
$notificationColorMidtoneHover: color.adjust($notificationColorMidtone, $lightness: -5%);
$notificationColorMidtoneActive: color.adjust($notificationColorMidtone, $lightness: -10%);

// Dark
$notificationColorHoverDark: color.adjust($notificationColor, $lightness: 5%);
$notificationColorActiveDark: color.adjust($notificationColor, $lightness: 10%);
$notificationColorCardDark: #918057;
$notificationColorCardHoverDark: color.adjust($notificationColorCard, $lightness: 5%);
$notificationColorCardActiveDark: color.adjust($notificationColorCard, $lightness: 10%);
$notificationColorFocusDark: #9f8c60;
$notificationColorFocusHoverDark: color.adjust($notificationColorFocus, $lightness: 5%);
$notificationColorFocusActiveDark: color.adjust($notificationColorFocus, $lightness: 10%);
$notificationColorMidtoneDark: #bfb290;
$notificationColorMidtoneHoverDark: color.adjust($notificationColorMidtone, $lightness: 5%);
$notificationColorMidtoneActiveDark: color.adjust($notificationColorMidtone, $lightness: 10%);

// Midtones
$midtonesColorStatic: #e2e2e2;
$midtonesColorStaticHover: color.adjust($midtonesColorStatic, $lightness: -5%);
$midtonesColorStaticActive: color.adjust($midtonesColorStatic, $lightness: -10%);
$midtonesColorText: #454646;
$midtonesColorStaticDark: #454646;
$midtonesColorStaticDarkHover: color.adjust($midtonesColorStaticDark, $lightness: 5%);
$midtonesColorStaticDarkActive: color.adjust($midtonesColorStaticDark, $lightness: 10%);
$midtonesColorTextDark: #e2e2e2;

// Misc
$navigationMenuBackgroundColor: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95));
$navigationMenuBackgroundColorDark: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.95));
$shadowColor: rgba(0, 0, 0, 0.2);
$shadowColorDark: rgba(255, 255, 255, 0.2);
$tutorialVideoBackgroundColor: #f6f6f6;

/* Component colors */

// Buttons
$buttonBackgroundColor: $brandColor;
$buttonBackgroundColorHover: color.adjust($buttonBackgroundColor, $lightness: -5%);
$buttonBackgroundColorActive: color.adjust($buttonBackgroundColor, $lightness: -10%);
$buttonBackgroundColorDisabled: $midtonesColorStatic;
$buttonBackgroundColorLoading: $contentBackgroundColorCard;
$buttonTextColor: #f4f4f4;
$buttonBackgroundColorDark: $brandColorAlternate;
$buttonBackgroundColorHoverDark: color.adjust($buttonBackgroundColorDark, $lightness: 5%);
$buttonBackgroundColorActiveDark: color.adjust($buttonBackgroundColorDark, $lightness: 10%);
$buttonBackgroundColorDisabledDark: $midtonesColorStaticDark;
$buttonBackgroundColorLoadingDark: $contentBackgroundColorCardDark;
$buttonTextColorDark: #f4f4f4;
$buttonTextColorAlternate: #1a1a1a;

// Button secondary tint
$buttonSecondaryBackgroundColor: $contentBackgroundColorCard;
$buttonSecondaryBackgroundColorHover: color.adjust($buttonSecondaryBackgroundColor, $lightness: -5%);
$buttonSecondaryBackgroundColorActive: color.adjust($buttonSecondaryBackgroundColor, $lightness: -10%);
$buttonSecondaryBackgroundColorDark: $contentBackgroundColorCardDark;
$buttonSecondaryBackgroundColorHoverDark: color.adjust($buttonSecondaryBackgroundColorDark, $lightness: 5%);
$buttonSecondaryBackgroundColorActiveDark: color.adjust($buttonSecondaryBackgroundColorDark, $lightness: 10%);

// Inputs
$inputBorderColorInactive: $midtonesColorStatic;
$inputBorderColorActive: $brandColor;
$inputBorderColorInactiveDark: $midtonesColorStaticDark;
$inputBorderColorActiveDark: $brandColorAlternate;

// Text
$textColor: $brandColor;
$textColorHover: color.adjust($textColor, $lightness: 5%);
$textColorActive: color.adjust($textColor, $lightness: 10%);
$textColorAlternate: $brandColorAlternate;
$textColorLight: $brandColorAlternate;
$textColorLightHover: color.adjust($textColorLight, $lightness: -5%);
$textColorLightActive: color.adjust($textColorLight, $lightness: -10%);
