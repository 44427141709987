/* Sizing */

// Fonts

$fontSizeHuge: 4rem; // 64px
$lineHeightHuge: 6rem;

$fontSizeFocal: 2.5rem; // 40px
$lineHeightFocal: 3.75rem;

$fontSizePageHeader: 2rem; // 32px
$lineHeightPageHeader: 3rem;

$fontSizeCardHeader: 1.5rem; // 24px
$lineHeightCardHeader: 2.25rem;

$fontSizeSectionHeader: 1.25rem; // 20px
$lineHeightSectionHeader: 1.875rem;

$fontSizeSubsectionHeader: 1rem; // 16px
$lineHeightSubsectionHeader: 1.5rem;

$fontSizeMd: 1rem; // 16px
$lineHeightMd: 1.5rem;

$fontSizeSm: 0.875rem; // 14px
$lineHeightSm: 1.3rem;

$fontSizeXs: 0.75rem; // 12px
$lineHeightXs: 1.125rem;

// Other

$buttonHeight: 40px;
$cardWidth3ColumnDesktop: 287px;
$cardWidth4ColumnDesktop: 208px;
$cardWidthDesktop: $cardWidth3ColumnDesktop;
$cardWidthMobile: 315px;
$inputHeight: 40px;
$mainMenuHeight: 60px;
// Height is button height + top and bottom padding + bottom safe area inset
$mainMenuTabsHeight: $buttonHeight + 15px + 18px;
$mainMenuTabsHeightPlusBottomSafeArea: calc(#{$mainMenuTabsHeight} + var(--safeAreaInsetBottom));
$minClickableHeight: 44px;
$statusBannerHeight: 54px;

/* Spacing */

// Margin

$buttonMessageMargin: 5px;
$buttonWithButtonMargin: 15px;
$contentMarginXs: 5px;
$contentMarginSm: 10px;
$contentMarginMd: 20px;
$contentMarginLg: 30px;
$contentMarginXl: 40px;
$contentMarginXxl: 50px;
$contentMarginHuge: 100px;

// Padding

$bottomOverflowPadding: 250px;
$buttonPadding: 15px;
$inputPadding: 15px;

/* Timing */

$timingInstant: 100ms;
$timingShort: 250ms;
$timingMedium: 500ms;
$timingLong: 1000ms;

/* Misc */

// Border radius

$buttonBorderRadius: 5px;
$cardBorderRadius: 5px;
$inputBorderRadius: 5px;

// Max widths

// Of the most common mobile screen sizes, 414px was the widest. We want to allow content to fill devices around that size.
$contentMaxWidthDesktop: 1155px;
$contentMaxWidthMobile: 450px;
$floatingButtonsMaxWidthDesktop: 423px - ($contentMarginLg * 2);
$floatingButtonsMaxWidthMobile: 335px - ($contentMarginLg * 2);
$mediaPopupMaxWidthDesktop: 423px;
$mediaPopupMaxWidthMobile: 335px;

// Effects

$imageZoomOnHoverScale: scale(1.2);
$imageZoonOnHoverTiming: $timingMedium;
