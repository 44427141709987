@import 'OK/styles/util';

.layout {
  padding-top: $mainMenuHeight;
}

.placeholderContainer {
  padding-top: $contentMarginLg;
}

.unpublishedMessage {
  margin: 0;
}

@include forLightTheme() {
  .placeholderContainer {
    background-color: $contentBackgroundColorCard;
  }
}

@include forDarkTheme() {
  .placeholderContainer {
    background-color: $contentBackgroundColorCardDark;
  }
}
